p {
    color: #edf2f4;
}

#projects-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
}

#projectstable {
    color: var(--white);
    display: flex;
    justify-content: center;
    text-align: center;
}

.projectsimg {
    height: 8em;
    width: 15em;
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 2vh auto;
}

.project {
    width: 20%;
    height: 20%;
    margin-bottom: 2rem;
    margin-left: 10px;
    margin-right: 10px;
    padding: .5rem;
    background-color: white;
    border-radius: 25px;
    transition: .5s;
    flex-basis: 20%;
    font-size: 2vw;
}

.project img {
    width: 100%;
    object-fit: scale-down;
    height: auto;
    max-height: 10em;
}

#projectHeader {
    display: flex;
    justify-content: center;
}

.project h2 {
    color: black;
    display: flex;
    justify-content: center;
    font-size: 100%;

}

.project p {
    margin-top: 1rem;
    font-size: 50%;
    color: black;
    display: flex;
    justify-content: center;
    text-align: center;
}

.projects-container a {
    display: contents;
    justify-content: center;
}

.project:hover {
    width: 22%;
    flex-basis: 22%;
    font-size: 2.2vw;
}