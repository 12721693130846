#technologies-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5vh 5vw;
}

#tech-title {
    text-align: center;
}

#technologies-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.tech-img {
    max-width: 20vw;
    min-width: 12vw;
    max-height: 15vh;
    object-fit: contain;
}