:root {
    --dblue: #1b4058;
    --lblue: #C1DBEC;
    --white: #edf2f4;
    --orange: #f58f29;
}

body::-webkit-scrollbar {
    display: none; /*Chrome, Safari, Opera*/
}

body {
    background: linear-gradient(to right, #0f0c29, #302b63, #24243e);
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow-x: hidden;
}

.whitetext {
    color: var(--white);
}

header {
    font-size: 40px;
    font-family: 'Audiowide';
    color: var(--white);
}

h1 {
    font-size: 40px;
    font-family: 'Audiowide';
    color: var(--white);
}

h2 {
    font-size: 30px;
    font-family: 'Kelly Slab';
    color: var(--white);
}

p {
    font-family: 'Sanchez';
    color: var(--white);
    font-size: 20px;
}

#aboutmediv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10vh 5vw 5vh;
    text-align: center;
    /**height: 60vh;*/
}

#startingtitle {
    font-family: 'Audiowide', 'Times New Roman', Times, serif;
    position: relative;
    text-align: center;
    top: 250px;
    color: var(--white);
}

#startingdiv {
    height: 800px;
    margin: -50px -8px;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons {
    position: relative;
    top: -20px;
}

    .buttons a {
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 50px;
        background-color: var(--white);
        margin: -50px -8px;
        top: 50%;
    }

#ablinks a {
    border: 2px solid transparent;
    border-radius: 3px;
    font-family: Audiowide;
    text-decoration: none;
    color: var(--white);
    transition: ease .3s;
    display: inline;
    float: right;
    margin: 5px;
    margin-top: 13px;
}

    #ablinks a:hover {
        border-bottom: 2px solid var(--orange);
    }

.Name {
    float: left;
}

.quote {
    color: var(--orange)
}

#meface {
    border-radius: 50%;
    margin: auto;
    height: 250px;
}



.kellyslab {
    font-family: 'Kelly Slab', Arial, Helvetica, sans-serif;
    color: var(--white);
}



.pacman {
    margin: 40px 10px;
    position: absolute;
    right: -50vw;
    transition-timing-function: linear;
    transition: 7s;
    display: flex;
    flex-direction: column;
    z-index: 5;
}

.pacman-top {
    background-color: yellow;
    height: 200px;
    width: 400px;
    border-radius: 200px 200px 0 0;
    animation: spin1 0.5s infinite linear;
    transform-origin: bottom center;
}

.pacman-bottom {
    background-color: yellow;
    height: 200px;
    width: 400px;
    border-radius: 0 0 200px 200px;
    animation: spin2 0.5s infinite linear;
    transform-origin: top center;
}

.dot {
    display: block;
    position: absolute;
    right: -10vh;
    width: 6vh;
    height: 6vh;
    border-radius: 50%;
    background-color: #edf2f4;
    transition: 3s;
    z-index: -1;
}

@keyframes spin1 {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(35deg);
    }
}

@keyframes spin2 {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-35deg);
    }
}

@media screen and (max-width: 768px) {
    .tech-img {
        max-width: 30%;
    }

    #technologies-div {
        column-count: 3;
        flex-wrap: wrap;
    }

    #startingdiv {
        height: 50vh;
        margin-top: 20vh;
    }
}

#titlediv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10vh;
    text-align: center;
    height: 50vh;
    padding-top: 0vh;
}

#titlediv h1 {
    font-size: 10vh;
    margin: 0;
}

#lastname, #firstname {
    padding: 3vw;
}