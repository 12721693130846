.topbar {
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
}

.dropdown-content {
    display: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 1;
    transition: visibility .5s, opacity .5s;
}

.dropdown-content.show {
    background-color: whitesmoke;
    width: 110px;
    display: block;
    position: absolute;
    visibility: visible;
    opacity: 100;
    top: 52px;
    right: 0px;
    padding-right: 2px;
    justify-content: flex-end;
    transition: visibility .5s, opacity .5s linear;
    border-bottom-left-radius: 15px;
}

.dropdown-content a {
    color: black !important;
}