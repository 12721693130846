#resumeheader {
    margin-top: 10vh;
    margin-bottom: 2vh;
}

#resume-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#resume-pdf {
    width: 90vw;
    height: 120vh;
}

#download-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    background-color: #0f0c29;
    color: #edf2f4;
    height: 6vh;
    border: 2px solid #edf2f4;
    border-radius: 8px;
    margin-bottom: 2vh;
}

#download-button:active {
    color: #0f0c29;
    background-color: #edf2f4;
    transition: .15s;
}