.titlecontent {
    background-color: #edf2f4;
    width: 90vw;
    border-radius: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1vh;
    margin: 2vh;
}

p {
    color: #0f0c29; 
    margin: 0;
}

.contacttitle {
    font-size: 3vh;
    margin: 0;
    color: #0f0c29;
}