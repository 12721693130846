td {
    color: inherit;
    font-family: 'Sanchez';
    font-size: 18px;
    vertical-align: top;
}

th {
    color: inherit;
    font-family: 'Kelly Slab';
    font-size: 25px;
    vertical-align: top;
}

#employers td a {
    text-decoration: none;
    color: var(--white);
    border: 2px solid transparent;
    border-radius: 3px;
    transition: .5s;
}

    #employers td a:hover {
        border-bottom: 2px solid var(--orange);
    }

a {
    color: var(--white);
    text-decoration: none;
}

#expdiv {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 10vh;
    flex-wrap: wrap;
}

#exptable {
    width: 85vw;
    border-spacing: 20px 5px;
}

#exptitle {
    font-family: 'Audiowide';
    margin: 5vh 0 0;
    text-align: center;
}

.jobsjobtitle {
    color: inherit;
    font-family: 'Kelly Slab';
    font-size: 100%;
    margin: 1em 1em 0;
}

.jobsjobcontainer {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3vh;
    border-radius: 25px;
    min-width: 20vw;
    max-width: 28vw;
    transition: ease .5s;
    font-size: 2vw;
    height: fit-content;
}

@media screen and (min-width: 800px) {
    .jobsjobcontainer:hover {
        width: 30%;
        max-width: 30vw;
        font-size: 2.14vw;
    }
    
}

@media screen and (max-width: 800px) {
    .jobsjobcontainer {
        min-width: 80vw;
        max-width: 80vw;
        height: auto;
        font-size: 4vh;
    }
    
}

.jobsjobcompany {
    color: black;
    font-family: 'Sanchez';
    font-style: italic;
    text-decoration: underline;
    font-size: 50%;
}

.jobsjobcompany:hover {
    color: var(--orange);
    transition: .2s;
}

#jobsdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.jobsjobdesc {
    color: black;
    font-family: 'Sanchez';
    font-size: 50%;
    margin: 1em;
}